import React from 'react';

export default function Blog() {
    return (
        <div>
            <div style={{ padding: '5rem 1rem 1rem 1rem' }}></div>
            <h1 style={{ fontSize: '2rem' }}>Under Construction</h1>
            <p style={{ fontSize: '1rem' }}>
                I will put details on clubs, club events, major career events,
                and occasional stuff of friends :)
            </p>
        </div>
    );
}